<template>
  <b-message :title="$route.meta.title" :closable="false" class="bcw is-size-7" type="is-primary">
    <b-field>
      <b-button
          size="is-small"
          @click="upsertMethodsPayment()"
          type="is-twitter"
          icon-right="id-card">
        Ajouter un moyen de paiement
      </b-button>

      <b-modal
        :active.sync="modalMethodsPayment"
        has-modal-card
        :can-cancel="['x']"
        trap-focus
        aria-role="dialog"
        aria-modal>
        <form @submit="validateForm" >
          <div class="modal-card" style="width: 450px">
            <header class="modal-card-head">
              <p class="modal-card-title is-size-5">Édition</p>
            </header>
            <section class="modal-card-body">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <b-field label="Label">
                    <b-input size="is-small" v-model="dataMethodsPayment.label" :disabled="dataMethodsPayment.created"></b-input>
                  </b-field>
                </div>

                <div class="column is-12">
                  <b-field label="Donne des points ?">
                    <b-switch type="is-success" v-model="dataMethodsPayment.is_loyalty"></b-switch>
                    {{ dataMethodsPayment.is_loyalty ? 'Oui' : 'Non' }}
                  </b-field>
                </div>
              </div>
              <b-button
                  size="is-small"
                  type="is-twitter"
                  native-type="submit"
                  :disabled="dataMethodsPayment.label.toString().length < 1">
                Envoyer
              </b-button>
            </section>
          </div>
        </form>
      </b-modal>
    </b-field>
    <b-table
        per-page="20"
        hoverable
        striped
        mobile-cards
        paginated
        default-sort-direction="desc"
        sort-icon="arrow-down-to-line"
        sort-icon-size="is-small"
        default-sort="to_waiting"

        icon-pack="far"
        pagination-size="is-small"
        :data="data">
      <b-table-column
        field="label"
        label="Label"
        searchable
        sortable
        centered>

        <template v-if="true" #searchable="props">
          <b-input
              v-model="props.filters['label']"
              placeholder="Moyen de paiement"
              class=""
              icon="magnifying-glass"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row['label'] }}
        </template>
      </b-table-column>

      <b-table-column
          field="is_loyalty"
          label="Donne des points ?"
          centered
          sortable
          v-slot="props">

        <b-tag :type="props.row.is_loyalty ? 'is-success' : 'is-danger'" size="is-small">{{ props.row.is_loyalty ? 'Oui' : 'Non' }}</b-tag>
      </b-table-column>

      <b-table-column
          field="to_waiting"
          label="Validation"
          centered
          sortable
          v-slot="props">
        <b-tag :type="props.row.to_waiting ? 'is-success' : 'is-primary'" size="is-small" v-if="props.row.to_waiting">En attente</b-tag>
      </b-table-column>
      <b-table-column
          field="created"
          label="Créé le"
          centered
          sortable
          v-slot="props">

        <span>{{ $moment(props.row.created).format('DD/MM/YYYY à  HH:mm') }}</span>
      </b-table-column>

      <b-table-column
          field="created"
          label="Modifié le"
          centered
          sortable
          v-slot="props">

        <span>{{ props.row.updated ? $moment(props.row.updated).format('DD/MM/YYYY à HH:mm') : '-' }}</span>
      </b-table-column>

      <b-table-column v-slot="props">
        <b-button
            size="is-small"
            icon-left="pen-to-square"
            @click="upsertMethodsPayment(props.row)">
          Modifier
        </b-button>
      </b-table-column>
    </b-table>
  </b-message>
</template>

<script>
export default {
  methods: {
    validateForm: function (event) {
      event.preventDefault();
      let data = {
        "label": this.dataMethodsPayment.label,
        "is_loyalty": this.dataMethodsPayment.is_loyalty ? true : false
      }

      this.$cx.post('transactions/upsert-methods-payment', data)
        .then(({count, mp}) => {
          this.count = count || 0
          this.data = mp || []
          if(this.dataMethodsPayment.created) {
            this.$store.state.paymentMethodsNew -= 1
            if (this.$store.state.paymentMethodsNew <= 0) {
              this.$store.state.paymentMethodsNew = 0
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
      this.updateMethodsPayment(event)
    },
    upsertMethodsPayment(data= {label: '', to_waiting: false, created: null}) {
      this.modalMethodsPayment = !this.modalMethodsPayment;
      this.dataMethodsPayment = data
    },
    updateMethodsPayment(data) {
      this.modalMethodsPayment = !this.modalMethodsPayment;
    },
    getPaymentMethods() {
      this.$cx.post('transactions/upsert-methods-payment?action=get', {})
          .then(({count, mp}) => {
            this.count = count || 0
            this.data = mp || []
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  },
  beforeMount() {
    this.getPaymentMethods()
  },
  watch: {
    "$store.state.paymentMethodsNew" (newVal) {
      console.log('newVal', newVal)
      this.getPaymentMethods()
    }
  },
  data() {
    return {
      isLoading: false,
      dataMethodsPayment: {label: '', to_waiting: false, created: null},
      modalMethodsPayment: false,
      data: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
          centered: true
        },
        {
          field: 'label',
          label: 'Nom',
          centered: true
        },
        {
          field: 'to_waiting',
          label: 'Donne des points ?',
          centered: true
        },
        {
          field: 'is_loyalty',
          label: 'En attente de validation',
          centered: true
        },
      ]
    }
  }
}
</script>